.form-wrapper{
    min-height: 300px;
    width: 100%;
    max-width: 400px;
    min-width: 350px;
    background: rgba(255, 246, 246,0.7);
    /*box-shadow: 2px 2px 6px rgba(46, 46, 46, 0.8);*/
    border-radius: 15px;
    padding:30px 40px;
    margin: 10px;
    /*background-image: url('../assets/images/wrapper-background-2.svg');*/
    background-repeat: no-repeat;
    background-size: 100%;
    box-sizing: border-box;
}

.div-wrapper{
    background-image: url('../assets/images/background-01.png');
    background-repeat: no-repeat;
    background-size: 100%;
}

.form-title{
    font-size:18px;
    font-weight: bold;
    color: #235079;
    padding-bottom: 20px;
    text-transform: uppercase;
}

.form-title-2{
    font-size:18px;
    font-weight: bold;
    color: #fcfcfc;
    padding-bottom: 20px;
    text-transform: none;
    text-align: center;
}

.text-input{
    width: 100%;
    padding: 10px;
    margin: 10px 0px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #235079;
    font-size:16px;
    color:#333333;
}

.text-field-data {
   /*  width: 100%;
    padding: 10px;
    padding-left: 20px;
    margin: 10px 0px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 0;
    font-size: 18px;
    color: #ffffff; */
    padding: 15px 20px;
    outline: none;
    font-size: 14px;
    font-weight: bold;
    font-family: chewy;
    min-width: 150px;
    width: 90%;
    box-sizing: border-box;
    position: relative;
    margin: 8px;
    display: flex;
    flex-direction: column;
    border: 3px solid rgb(206, 115, 15);
    justify-content: center;
    border-radius: 20px;
    height: 40px;
    background: rgba(250, 243, 227, 0.789);
    
}

.text-input-label{
    font-weight: bold;
    font-size: 14px;
    color:#4d4c4c;
}

.text-input-label-2{
    font-weight: bold;
    font-size: 14px;
    color: #ffffff;
    background: #00000082;
    padding: 4px 10px;
    border-radius: 20px;
}

.form-footer{
    padding:15px 0px;
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
}

.form-links{
    text-decoration: none;
    /* font-weight: bold; */
    color: #235079;
    font-size: 14px;
}

.form-links-2{
    text-decoration: none;
    font-weight: bold;
    font-size: 14px;
    color: #ffffff;
    background: #00000082;
    padding: 4px 10px;
    border-radius: 20px;
    text-align: center;
}

.form-links:hover{
    font-weight: bold;
}

.input-error-block{
    background: #ffe9c8;
    padding: 5px;
    border-radius: 3px;
    border-left: 5px solid red;
    font-size: 12px;
    box-shadow: 1px 1px 2px rgb(100 100 100 / 40%);
    font-weight: bold;
    color: #38251c;
}

.main-background{
    background-color: #e5cda1;
    width: 100%;
    /* justify-content: center; */
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    display: flex;
    min-height: calc(100vh - 80px);
}

.main-page-content{
    flex-grow: 1;
}

label{
    font-size: 13px;
    font-weight: bold;
    color:#555555;
}


/** Home Page **/
.top-banner{
    padding: 10px;
    color: rgb(77, 106, 125);
    font-weight: bold;
    width: 100%;
    min-height: 60px;
    background-color: #68cef6;/*rgb(255, 255, 255); background-color: #68cef6;*/
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:28px;
    
}

.top-banner-text-1{
    color:white;
    margin: 8px;
    white-space: nowrap;
    display: inline-block;
}

.top-banner-text-2{
    color:white;
    margin: 8px;
    white-space: nowrap;
    display: inline-block;
}

.floating-banner{
    height: 200px;
    width: 100%;
    background: #68cef6;
}

.main-banner{
        background-image: url('../assets/images/background-02.png');
        background-repeat: no-repeat;
        background-size: 100%;
}

.floating-text-container{
    position: absolute;
    padding: 20px 30px;
    margin: 0px;
    box-sizing: border-box;
    /*background: rgb(113, 203, 220);
    border-radius: 0px 0px 50% 50%;*/
}

.floating-text-nav{
    display: flex;
    width: 100%;
    /*position: absolute;
     top: 40%; */
    justify-content: center;
    box-sizing: border-box;
}

.floating-text{
    text-align: center;
    font-size: 24px;
    padding: 10px;
    color: rgb(51 51 51);
    width: 100%;
    line-height: 1.7;
}

.navigation-card{
    margin: 10px;
    display: inline-block;
    /* box-shadow: rgb(100 100 100 / 90%) 0px 0px 5px; */
    border-radius: 10px;
   /*  background-color: rgb(129, 58, 0); */
    overflow: hidden;
    border: 0px solid rgb(238, 238, 238);
}

.navigation-card-title{
    font-size: 24px;
    color: white;
    font-family: 'chewy';
    font-weight: normal;
    padding: 20px;
}

.navigation-card-description{
    width: 300px;
    height: 200px;
    font-size: 20px;
    color: #444444;
    padding: 20px;
    box-shadow: 2px 2px 5px 5px rgb(50 50 50 / 50%);
    background: #ecddc1;
    border-radius: 20px;
    font-family: 'Chewy'
}

.new-activity-marker{
    position: absolute;
    right: -10px;
    /* top: -10px; */
    color: white;
    box-shadow: 2px 2px 4px rgb(10 10 10 / 80%);
    border-radius: 0px 0px 10px 10px;
    background-color: red;
    padding: 4px 20px;
    font-size: 14px;
    font-weight: bold;
}

.activity-card-wrapper{
    /* margin: 10px; */
    display: inline-block;
    /* box-shadow: rgb(100 100 100 / 90%) 0px 0px 5px; */
    width:100%;
    border-radius: 20px;
    overflow: hidden;
    border: 0px solid rgb(238, 238, 238);
    position: relative;
    cursor:pointer;
}

@keyframes openCardInfoAnimation{
    from {height: 60px;}
    to {height: 80px;}
}

.activity-card-info{
    box-sizing: border-box;
    width: 230px;
    height: 60px;
    bottom: 0px;
    background-color: rgb(255, 255, 255);
    padding: 5px;
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.activity-card-info:hover{
    animation-name: openCardInfoAnimation;
    animation-duration: 0.5s;
    height: 80px;
    background-color:#fff0d9;
    
    color:#ffffff;
    cursor: pointer;
}

.activity-history-card-info{
    box-sizing: border-box;
    width: 230px;
    height: 60px;
    bottom: 0px;
    background-color: #1C75BC;
    color:#FFFFFF;
    padding: 5px;
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.activity-history-card-label{
    font-weight: bold;
}

.page-text-header{
    font-size: 25px;
    font-weight: bold;
    color: #8b3436;
    line-height: 60px;
}
.page-text{
    max-width: 500px;
    font-size: 16px;
    padding: 20px;
    line-height: 26px;
    color: #444444;
}

.et-envelope:before {
    content: "\e028";
}

.footer{
    min-height: 200px;
    background-color: rgb(51, 51, 51);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;    
    flex-direction: column;
}

.inner-footer{
    padding: 20px;
    background-color: rgb(51, 51, 51);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}



.footer-category{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-link{
    line-height: 26px;
    font-size: 20px;
    padding: 12px 24px;
    color: #fff;
    border-radius: 57px;
    text-decoration: none;
    border: 0;
    box-sizing: border-box;
    padding: 10px 45px;
    display: inline-block;
    font-size: 16px;
    border-radius: 30px;
    line-height: 18px;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    text-align: center;
}

.is-narrow-screen{
    display: block;
}

.is-wide-screen{
    display: none;
}

@media screen and (min-width: 850px) {
    .floating-banner{
        display: none;
    }

    .floating-text-container{
        left:0px;        
        max-width: 600px;
    }

    .is-narrow-screen{
        display: none;
    }

    .is-wide-screen{
        display: block;
    }
}

.data-table-wrapper{
    margin: 10px;
    /*width:95%;*/
    display: inline-block;
    box-shadow: rgb(100 100 100 / 90%) 0px 0px 5px;
    border-radius: 5px;
    overflow: hidden;
    overflow-x: auto;
}

.data-table-wrapper > table {
    width: 100%;
}

.data-table-header{
    color:white;
    background-color: #1C75BC;
}
.data-table-header-cell{
    padding:10px 15px;
    text-align: center;
    font-size:14px;
    font-weight: normal;
}

.data-table-cell{
    padding:10px;
    text-align: center;
    color:#444444;
    font-size: 13px;
    border-right: 1px solid #EEEEEE;
    font-weight: normal;
}

tr:nth-child(even) {
    background-color: #EEEEEE;
}

.user-info-avartar-big{
    margin-right: 10px;
    height: 80px;
    width: 80px;
    background-color: rgb(0, 153, 119);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 38px;
    color: #ffffff;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
}

.styled-input-label{
    font-size: 16px;
    font-weight: normal;
    color: #f3f3f3;
    padding:5px 25px
}

.styled-input-field{
    padding: 8px 15px;
    outline: none;
    font-size: 14px;
    font-weight: bold;
    font-family: chewy;
    min-width: 150px;
    width: 90%;
    box-sizing: border-box;
    position: relative;
    margin: 8px;
    display: flex;
    /* flex-direction: column; */
    border: 3px solid rgb(206, 115, 15);
    justify-content: center;
    border-radius: 20px;
    height: 45px;
    background: rgb(245, 204, 96);
}

.styled-input-field-disabled{
    padding: 15px 20px;
    outline: none;
    font-size: 14px;
    font-weight: bold;
    font-family: chewy;
    min-width: 150px;
    width: 90%;
    box-sizing: border-box;
    position: relative;
    margin: 8px;
    display: flex;
    /* flex-direction: column; */
    border: 3px solid rgb(206, 115, 15);
    justify-content: center;
    border-radius: 20px;
    height: 50px;
    background: rgb(191, 191, 191);
}


*::placeholder{
    color:#e76d0c;
    font-weight: normal;
    font-size: 17px;
}
