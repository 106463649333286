.loading-page-view
{
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100vh;
    width: 100vw;
    padding: 20px;
    background: linear-gradient(90deg, rgb(221 169 255) 0%, rgb(252 216 216) 50%, rgb(239 225 206) 100%);
}

.loading-page-container
{
    background: none;
    display: flex;
    border-radius: 20px;
    position: relative;
    flex-grow: 1;
    
    height: 100vh;
}

.loading-page-activity-board{
    background:linear-gradient(0deg, rgba(34,193,195,1) 0%, #009688 100%);
    border-radius: 20px;
    position: relative;
    flex-grow: 1;
    box-shadow: 2px 2px 4px rgba(10,10,10,0.7);
    overflow: hidden;
}

@media screen and (max-width: 550px) {
    .loading-page-container
    {      
        flex-direction: column;
        height: 100vh;
        align-items: center;
    }

    .loading-page-activity-board{        
        min-height: 60vh;
        min-width: 90vw;
    }
}