

@font-face {
  font-family: 'TT Norms Pro Regular';
  font-style: normal;
  font-weight: normal;
  src:  url('./fonts/TT Norms Pro Regular.woff') format('woff');
  }

@font-face {
  font-family: 'Zyzol';
  src: local('Zyzol'), url('./fonts/Zyzol-Wyd3n.otf') format('truetype');
}

@font-face {
  font-family: 'Chewy';
  src: local('Chewy'), url('./fonts/Chewy-Regular.ttf') format('truetype');
}


* { 
  margin: 0; 
  padding: 0; 
} 

*,
*::before,
*::after { 
  box-sizing: inherit;
} 

html { 
  box-sizing: border-box;
  font-size: 62.5%; 
}  



body {

  font-family: 'TT Norms Pro Regular', sans-serif;
  /*font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;*/


  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*position: relative;*/

  overscroll-behavior-y: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} 