.navigation-bar{
    width: 100%;
    height: 100px;
    background-color: #e5cda1;;
    align-items: center;
    justify-content: space-between;
    display: flex;
    position: fixed;
    z-index: 20;
    /*padding: 5px;*/
    border-bottom: 1px solid #665e5e;
    box-shadow: 0px 2px 2px rgb(13 13 13 / 10%);

}

.navigation-bar-item{
    display:flex;
    align-items:center
}

.navigation-bar-button-1{
    border: 4px solid rgb(26, 26, 26);
    box-sizing: border-box;
    padding: 8px 20px;
    margin:0px 5px;
    display: inline-block;
    font-size: 14px;
    border-radius: 30px;
    line-height: 18px;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    text-align: center;
    transition: opacity .25s,background-color .25s,color .25s,border .25s,box-shadow .25s;
    outline-style: none;
    font-family: TTNorms-Medium,Arial,sans-serif;
    font-weight: bold;
    background-color: #0f75bc;
    background-color: #b6580e;
    flex-wrap: nowrap;
    color: white;
    /* box-shadow: 1px 1px 2px rgba(100,100,100,0.4);*/
}

.navigation-bar-button-2{
    border: 4px solid white;
    box-sizing: border-box;
    padding: 8px 20px;
    margin:0px 5px;
    display: inline-block;
    font-size: 14px;
    border-radius: 30px;
    line-height: 18px;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    text-align: center;
    transition: opacity .25s,background-color .25s,color .25s,border .25s,box-shadow .25s;
    outline-style: none;
    font-family: TTNorms-Medium,Arial,sans-serif;
    font-weight: bold;
    background-color: #0f75bc;
    background-color: #f68a44;
    flex-wrap: nowrap;
    color: white;
    /* box-shadow: 1px 1px 2px rgba(100,100,100,0.4);*/
}

.app-title{
    font-weight: bold;
    color: #0f75bc;
    font-size: 38px;
    font-style: normal;
}

.app-logo {
    height: 100%;
    padding: 2px;
    /*pointer-events: none;*/
    user-select: none;
    cursor: pointer;
    background:none;
    align-items: center;
    display: flex;
}

.app-logo:hover{
    background: #e76d0c4d;
}

.user-info-avartar{
    /* margin-right: 10px; */
    height: 40px;
    width: 40px;
    background-color: rgb(0, 153, 119);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #ffffff;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    border-radius: 50%;
}

.user-info-avartar:hover{
    background-color: #0f75bc;
}

.user-info-menu{
    position: absolute;
    min-width: 100px;
    min-height: 30px;
    color: #0f75bc;
    background: #FFFFFF;
    box-shadow: 2px 2px 5px rgb(30 30 30 / 80%);
    top: 70px;
    font-size: 15px;
    border-radius: 5px;
    padding: 10px 15px;
}
.user-info-menu > ul{
   list-style: none;
}
.user-info-menu > ul > li{
    white-space: nowrap;
    cursor: pointer;
    margin-bottom:5px;
    margin-top:5px;
    padding:5px 10px;
}

.user-info-menu > ul > li:hover{
    background-color: #EEEEEE;
    border-radius: 5px;
}
.user-info-wrapper{
    margin-right: 10px;
}

.user-info-name{
    font-size: 16px;
    color: #333333;
    font-weight: bold;
}

.user-info-email{
    font-size: 11px;
    padding-top: 2px;
    color: #555555;
}


/* Side Menu Button */
.side-menu-button-bar{
    display: inline-block;
    margin-bottom: 6px;
    height: 2px;
    background-color: #14709c;
    width: 24px;
}

.side-menu-button-bar-1{   
    margin-bottom: 6px;    
}

.side-menu-button-bar-2{   
    margin-bottom: 6px;    
}

.side-menu-button-bar-3{   
    margin-bottom: 1px;   
}



.side-menu-button{
    display: none;
    flex-direction: column;
    margin: 5px 5px 5px 10px;
    cursor: pointer;
    background:none;
    padding: 10px;
    border-radius: 5px;

    padding: 6px;
    border-radius: 5px;
    width: 36px;
    height: 36px;
    box-sizing: border-box;
}



@media screen and (max-width: 550px) {

    .navigation-bar{
        width: 100%;
        height: 60px;   
    }

    .side-menu-button-active{
        background: #bbe1f5;
        padding: 0px;
    }

    .side-menu-button-bar-1-active{   
        margin:0px;    
        transform:rotate(-45deg) translate(-8px, 16px);
        width:24px;
        transition: all .3s ease-in;
    }
    
    .side-menu-button-bar-2-active{   
        display:none;
    }
    
    .side-menu-button-bar-3-active{     
        margin:4px;    
        transform:rotateZ(45deg) translate(8px, 7px);
        width:24px;
        transition: all .3s ease-in;
    }

    .side-menu-button{
        display: flex;
    }

    .user-info-wrapper{
        display: none;
    }
    
}