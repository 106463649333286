.assessment-score-box{
    white-space: nowrap;
    margin: 5px 5px;
    font-size: 15px;
    background: green;
    padding: 10px 10px;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    border: 4px solid #ffc107;
    cursor: pointer;
    /* font-family: 'Chewy'; */
    display: flex;
    align-items: center;
}

.assessment-timer-box{
    white-space: nowrap;
    margin: 10px;
    font-size: 15px;
    background: green;
    padding: 5px 10px;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    border: 4px solid #ffc107;
    cursor: pointer;
   /*  font-family: 'Chewy'; */
}

.assessment-question-container
{
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: url(https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/assessment/bg-01.svg);
}

.question-block-wrapper{
    display: inline-flex;
    padding:20px;
    align-items: center;
    justify-content: center;
    min-width: 350px;
    max-width: 900px;
    /*max-height: 40vh;*/
    min-height: 100px;
    aspect-ratio: 16/9;
}

.info-block{
     background-color: rgba(255,255,255,0.9);
    padding: 20px;
    margin:20px;
    border-radius: 30px;
    color: rgb(16, 16, 16);
    font-size: 18px;
    box-shadow: 2px 2px 4px black;
    /* width:90%;
    height: 90%; */
    display: flex;
    align-items: center;
    justify-content: center; 
    box-sizing: border-box;
 
}

.question-block{
     background-color: rgb(251 224 170 / 90%);
    padding: 20px;
    border-radius: 30px;
    color: white;
    font-size: 38px;
    /* box-shadow: 2px 2px 4px black; */
   
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 25px solid rgba(248, 187, 33, 1);
    border-radius: 35px; */
}

@media (min-width: 550px) {
    .question-block{
        width: 90%;
        height: 90%;
        min-height: 40vh;
    }
}

@media (max-width: 550px) {
    .question-block{
        
        min-height: 25vh;
    }
}

.score-display{    
    padding: 5px 35px;
    background: linear-gradient(0deg, #e2b752, #cea204fc);
    border-radius: 20px;
    box-shadow: 1px 2px 3px rgb(30 30 30 / 40%) inset;
    margin: 0px 5px;    
}

.custom-pointer{
    cursor: url(https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/ENGLISH/G1/lesson1/pointer_3.svg) 16 16,auto;
}
