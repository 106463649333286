@keyframes openAnimation{
    from {max-width: 100px; opacity: 0.4;display: flex;}
    to {max-width: 250px;opacity: 1;display: flex;}
}

@keyframes closeAnimation{    
    0% {max-width: 250px;opacity: 1;display: flex;}
    95% {max-width: 50px;opacity: 0;display: flex;}
    100% {max-width: 50px; opacity: 0;display: none;}
}

.side-menu-container{
    /*padding: 10px;*/
    /*min-width: 150px;*/
    max-width: 250px;
    width:100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: fixed;
    background-color: rgba(255, 255, 255,0.9);
    border-right: 1px solid rgb(206, 206, 206);
    left: 0px;
    top: 100px;
    bottom: 0px;
    box-shadow: 2px 0px 20px rgb(10 10 10 / 90%);
    box-sizing: border-box;
}

.side-menu-close{
    /*display: none;*/
    animation-name: closeAnimation;
    animation-duration: 0.5s;
    display: none;
}

.side-menu-open{
    display: flex;
    animation-name: openAnimation;
    animation-duration: 0.5s;
}

.side-menu-backdrop{
    display:block;
    background-color: rgba(20, 20, 20, 0.4);
    backdrop-filter: blur(3px);
    position: fixed;
    top: 0px;
    width: 100vw;
    height: 100vh;
}

.side-menu-backdrop-show{
    display:block;
}

.side-menu-backdrop-hide{
    display:none;
}

@media screen and (min-width: 550px) {
    .side-menu-container {
        display: flex;
        box-shadow:none;
    }

    /*.side-menu-backdrop{
        display:none;
    }*/

    .side-menu-backdrop-show{
        display:none;
    }

    .side-menu-close{       
        display: flex;
        animation: none;
    }

    .side-menu-open{
        display: flex;
        animation: none;
    }
}


@media screen and (max-width: 550px) {
    .side-menu-container{
        top: 60px;
    }
}

.side-menu-links-container {
    list-style-type:none;
    /*padding:5px;*/
    padding-left:20px;
    margin: 0;
}

.side-menu-category-label {
    font-size: 14px;
    /*font-weight: bold;*/
    /* color:#0f75bc; */
    text-decoration: none;
    color:white;
    margin-top: 5px;  
    padding: 5px 0px;  
    text-decoration: none;
}

.side-menu-category-label-active{    
    font-weight: bold;
    width: 100%;
    /* background-color: #1c75bc; */
    padding: 5px;
    color: #FFFFFF;
    border-radius: 5px;
}

.side-menu-category-label-inactive{   
    
    width: 100%;
    /*background-color: #f3f6f9;*/
    padding: 5px 10px;
    border-radius: 5px;
}

.side-menu-category-label-inactive:hover{   
    
    width: 100%;
    background-color: #f3f6f9;
    padding: 5px 10px;
    border-radius: 5px;
}

.side-menu-links-item {
    
    color:#444444;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.side-menu-container-link {
    display: block;
    text-decoration: none;
    color: #464646;
    vertical-align: middle;
    padding: 5px 2px 5px 4px;
    margin: 0 -2px 0 -4px;
    position: relative;
    font-size:14px;

}

.side-menu-container-link:hover {
    background-color: #EEEEEE;
    border-radius: 3px;
    color:#0099ff;
}
