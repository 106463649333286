/*@font-face {
    font-family: 'Aclonica'; 
    src: url('https://fonts.googleapis.com/css2?family=Aclonica&display=swap');
}*/

@import url('https://fonts.googleapis.com/css2?family=Aclonica&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Chewy&display=swap');

.clickable-component {
    cursor: url(https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/ENGLISH/G1/lesson1/pointer_3.svg) 16 16,auto;
}

.game-primary-button{
    font-size: 30px;
    font-family: 'Aclonica';
    color: rgb(255, 255, 255);
    padding: 10px 30px;
    font-weight: bold;
    border-radius: 50px;
    background: #119a07;
    box-shadow: inset 5px 5px 0 -1px #54b345, inset -5px -5px 0 -1px #0c440a, 0 3px 5px rgb(0 0 0 / 12%), 0 8px 5px rgb(0 0 0 / 14%), 0 5px 5px rgb(0 0 0 / 20%);
    letter-spacing: 3px;
    display: flex;
    cursor: pointer;
    align-items: center;
}

.game-secondary-button{
    font-size: 30px;
    font-family: 'Aclonica';
    color: rgb(255, 255, 255);
    padding: 10px 30px;
    font-weight: bold;
    border-radius: 50px;
    background: #0c81cf;
    box-shadow: inset 5px 5px 0 -1px #4d86d1, inset -5px -5px 0 -1px #0a2844, 0 3px 5px rgb(0 0 0 / 12%), 0 8px 5px rgb(0 0 0 / 14%), 0 5px 5px rgb(0 0 0 / 20%);
    letter-spacing: 3px;
    display: flex;    
    cursor: pointer;
    align-items: center;
}

.game-cancel-button{

}

.game-title{
    color: #FFFFFF;
    font-size: 44px;
    font-family: 'Chewy';
    text-shadow: 4px 4px 5px #3e3e3e;
    text-align: center;
}

@keyframes strong-pulsate{
    0% { 
        transform:  scale(1);         
    } 

    50% { 
        transform:scale(.4);    
    }

    100% { 
        transform: scale(1.5);         
    } 
}

.strong-pulsate{    
    animation: strong-pulsate 2s infinite;
  }

@keyframes strong-pulsate-rotate{
    0% { 
        transform:  scale(1) rotate(10deg);         
    } 

    50% { 
        transform:scale(.4) rotate(0deg);    
    }

    100% { 
        transform: scale(1.5) rotate(-10deg);         
    } 
}

.strong-pulsate-rotate{    
  animation: strong-pulsate-rotate 2s infinite;
}

@keyframes pulsate{
    0% { 
        transform:  scale(1);         
    } 

    50% { 
        transform:scale(.9);    
    }

    100% { 
        transform: scale(1);         
    } 
}

.pulsate{    
  animation: pulsate 2s infinite;
}

.play-button-disabled{
    font-size: 30px;
    font-family: 'Aclonica';
    color: rgb(255, 255, 255);
    padding: 10px 30px;
    font-weight: bold;
    border-radius: 50px;
    background: #a0a0a0;
    box-shadow: inset 5px 5px 0 -1px #babbba, inset -5px -5px 0 -1px #414341, 0 3px 5px rgb(0 0 0 / 12%), 0 8px 5px rgb(0 0 0 / 14%), 0 5px 5px rgb(0 0 0 / 20%);
    letter-spacing: 3px;
    display: flex;
    opacity: 0.5;
    align-items: center;
    cursor:not-allowed;
}


.play-button-disabled > span{
    padding:5px 15px;
}

.play-button{
    font-size: 24px;
    font-family: 'Aclonica';
    color: rgb(255, 255, 255);
    padding: 10px 30px;
    font-weight: bold;
    border-radius: 50px;
    background: #119a07;
    box-shadow: inset 5px 5px 0 -1px #54b345, inset -5px -5px 0 -1px #0c440a, 0 3px 5px rgb(0 0 0 / 12%), 0 8px 5px rgb(0 0 0 / 14%), 0 5px 5px rgb(0 0 0 / 20%);
    letter-spacing: 3px;
    display: flex;
    align-items: center;
    /* cursor: pointer; */
}

.play-button > span{
    padding:5px 15px;
}

.play-button:hover{
    padding: 10px 35px;
}

.end-button{
    font-size: 24px;
    color: rgb(255, 255, 255);
    background: #ff4700;;
    padding: 10px 30px;
    font-weight: bold;
    border-radius: 50px;
    box-shadow: 2px 2px 1px rgb(128, 19, 0);
    background: #d67547;
    /*box-shadow: inset 5px 10px 0 -1px #eb9f7a, inset -5px -10px 0 -1px #bb4d20, 0 3px 14px rgb(0 0 0 / 12%), 0 8px 10px rgb(0 0 0 / 14%), 0 5px 5px rgb(0 0 0 / 20%);*/
    letter-spacing: 3px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.end-button > span{
    padding:5px 15px;
}

.end-button:hover{
    padding: 10px 40px;
}

.pause-button{
    font-size: 24px;
    color: rgb(255, 255, 255);
    background: #039aa5;;
    padding: 10px 30px;
    font-weight: bold;
    border-radius: 50px;
    box-shadow: 2px 2px 1px rgb(0, 77, 128);
    /*box-shadow: inset 5px 10px 0 -1px #eb9f7a, inset -5px -10px 0 -1px #bb4d20, 0 3px 14px rgb(0 0 0 / 12%), 0 8px 10px rgb(0 0 0 / 14%), 0 5px 5px rgb(0 0 0 / 20%);*/
    letter-spacing: 3px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.pause-button > span{
    padding:5px 15px;
}

.pause-button:hover{
    padding: 10px 40px;
}


.undo-button{
    font-size: 24px;
    color: rgb(255, 255, 255);
    background: #049d15;;
    padding: 10px 30px;
    font-weight: bold;
    border-radius: 50px;
    box-shadow: 5px 5px 1px rgb(0, 85, 14);
    letter-spacing: 3px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.undo-button > span{
    padding:5px 15px;
}

.undo-button:hover{
    padding: 10px 40px;
}

.points-view-box{
    border: 5px solid rgb(9 108 230);
    font-size: 24px;
    background-color: white;
    padding: 10px 15px;
    border-radius: 10px;
    font-weight: bold;
    font-style: italic;
}

.floating-container{
    /*width: 100px;*/
    height: 100px;
    cursor: pointer;
    position: absolute;
    display: flex;
    flex-direction: column;
    /* background-color: blue; */
    align-items: center;
}

.floating-container:hover{
    transform: scale(1.2);
}

.floating-container-text{
    font-size: 20px;
    font-weight: bold;
    color: rgb(9 73 115);
    padding: 10px 10px;
    border-radius: 10px;
    border: 5px solid rgb(8 96 154);
    background: rgb(255, 255, 255);
}

.option-text-container{
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: rgb(9 73 115);
    padding: 10px 10px;
    border-radius: 10px;
    border: 2px solid rgb(8 96 154);
    background: rgb(255, 255, 255);
    /* cursor:pointer; */
    box-shadow: 0px 0px 8px rgba(47, 47, 47, 0.8);
}

.blink {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}


.shrink {
    animation-name: shrink1;
   animation-duration: 1.0s;
   animation-timing-function: linear;
   animation-delay: 0s;
   animation-iteration-count: 1;
   animation-fill-mode: forwards;
   }
@keyframes shrink1 {
   0%   {transform:scale(1); display:flex; opacity:1}
   30% {transform:scale(0.6); display:flex; opacity:1}
   69.999% {transform:scale(0.3); display:flex; opacity:0}
   100% {transform:scale(0.1); display:none; opacity:0}
}


.grow {
    animation-name: grow1;
   animation-duration: 1.0s;
   animation-timing-function: linear;
   animation-delay: 0s;
   animation-iteration-count: 1;
   animation-fill-mode: forwards;
   }
@keyframes grow1 {
   0%   {transform:scale(0.1); display:none; opacity:0}
   30% {transform:scale(0.3); display:flex; opacity:1}
   69.999% {transform:scale(0.6); display:flex; opacity:1}
   100% {transform:scale(1); display:flex; opacity:1}
}