.button-1{
    border: 0;
    box-sizing: border-box;
    padding: 11px 24px;
    display: inline-block;
    font-size: 17px;
    border-radius: 30px;
    line-height: 12px;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    text-align: center;
    transition: opacity .25s,background-color .25s,color .25s,border .25s,box-shadow .25s;
    outline-style: none;
    font-family: TTNorms-Medium,Arial,sans-serif;
    font-weight: 500;
    background-color: #037d07;
    flex-wrap: nowrap;
    font-family: 'chewy';
    color: white;
    border:4px solid white;
    box-shadow: 1px 1px 2px rgb(100 100 100 / 40%);
}

.button-2{
    border: 0;
    box-sizing: border-box;
    padding: 6px 24px;
    display: inline-block;
    font-size: 17px;
    border-radius: 30px;
    line-height: 12px;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    text-align: center;
    transition: opacity .25s,background-color .25s,color .25s,border .25s,box-shadow .25s;
    outline-style: none;
    font-family: TTNorms-Medium,Arial,sans-serif;
    font-weight: 300;
    background-color: #0f75bc;
    background-color: #be1e2d;
    font-family: 'chewy';    
    border:4px solid rgb(24, 12, 12);
    flex-wrap: nowrap;
    color: white;
    box-shadow: 1px 1px 2px rgb(100 100 100 / 40%);

}

.button-3{
    line-height: 26px;
    font-size: 20px;
    padding: 12px 24px;
    background-color: #0f75bc;
    color: #fff;
    border-radius: 57px;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 6%), 0 2px 2px 0 rgb(0 0 0 / 12%);

    text-decoration: none;
    border: 0;
    box-sizing: border-box;
    padding: 10px 45px;
    display: inline-block;
    font-size: 19px;
    border-radius: 30px;
    line-height: 18px;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: center;
    transition: opacity .25s,background-color .25s,color .25s,border .25s,box-shadow .25s;
    outline-style: none;
    font-family: TTNorms-Medium,Arial,sans-serif;
    font-weight: 500;

}

.button-1:disabled{
    background-color: #9b9a9a;
    background-image:none;
}