.centered-view {
    /*min-height: calc(100vh - 100px);*/
    min-height: calc(100vh);
    width:100%;
    top: 100px;
    padding-top: 100px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    display: flex;
    /*background: linear-gradient(40deg, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);*/
    /*background:tomato;*/
    background:#e5cda1;
    background-size: 100%;
    /*position: relative;*/
}

.offset-centered-view{
    padding-left:0px;
}

@media screen and (min-width: 550px) {
    /*.centered-view{
        padding-left:250px;
    }*/
    
    .offset-centered-view{
        padding-left:250px;
    }
}

@media screen and (max-width: 550px) {
    .centered-view {
        top: 60px;
        padding-top: 60px;
    }
}