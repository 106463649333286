.button-1{
    display: inline-block;
    /* width: 100%; */
    text-align: center;
    /* max-width: 100%; */
    min-width: 8px;
    vertical-align: middle;
    outline: 0;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    font-size: 14px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -ms-touch-action: none;
    -webkit-font-smoothing: antialiased;
    /* border: 1px solid; */
    /* border-radius: 3px; */
    border-radius: 30px;
    /* text-transform: uppercase; */
    font-weight: bold;
    /* font-weight: 500; */
    /* border-color: #e76d0c #e15500 #e15500; */
    color: #fff;
    /* text-shadow: rgb(0 0 0 / 5%) 0 1px 0; */
    /*background-color: #ec7211;*/
    background-color: #0099ff;
    /* background-image: linear-gradient(
180deg
,#f67c1b 0,#e15500); */
    /* box-shadow: inset 0 1px 0 hsl(0deg 0% 100% / 20%); */
    padding: 8px 25px;
    border: 0px;
    box-shadow: 1px 1px 2px rgb(10 10 10 / 40%);
    text-decoration: none;

}




.button-1:disabled{
    background-color: #9b9a9a;
    background-image:none;
}

.button-2{
    display: inline-block;
    /* width: 100%; */
    text-align: center;
    /* max-width: 100%; */
    min-width: 8px;
    vertical-align: middle;
    outline: 0;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    font-size: 14px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -ms-touch-action: none;
    -webkit-font-smoothing: antialiased;
    /* border: 1px solid; */
    /* border-radius: 3px; */
    border-radius: 30px;
    /* text-transform: uppercase; */
    font-weight: bold;
    /* font-weight: 500; */
    /* border-color: #e76d0c #e15500 #e15500; */
    color: #ec7211;
    /* text-shadow: rgb(0 0 0 / 5%) 0 1px 0; */
    background-color: #0099ff;
    /* background-image: linear-gradient( 
180deg
 ,#f67c1b 0,#e15500); */
    /* box-shadow: inset 0 1px 0 hsl(0deg 0% 100% / 20%); */
    padding: 8px 25px;
    border: 0px;
    box-shadow: 1px 1px 2px rgb(10 10 10 / 40%);
    text-decoration: none;
    background: none;
    border: 0px solid #ff9900;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;  
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f142;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    /* background: rgb(204, 78, 6); */
    background: #f68a44;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }